<template lang="pug">
div
  TeaserCloseButton(v-if="isTeaserPage")
  CloseButton(v-else)
</template>
<script>
  import { mapGetters } from 'vuex';

  export default {
    components: {
      TeaserCloseButton: () =>
        import('@/components/Editor/Blocks/CloseButton/TeaserCloseButton.vue'),
      CloseButton: () => import('@/components/Editor/Blocks/CloseButton/CloseButton.vue'),
    },
    computed: {
      ...mapGetters(['isTeaserPage']),
    },
  };
</script>
